
    import { Component, Vue } from 'vue-property-decorator';
    import Header from './components/Header.vue';

    @Component({ components: { Header} })
    export default class App extends Vue {
        public supportedBrowser = true;
        data(): { title: string } {
            return {
                title: process.env.VUE_APP_TITLE || 'Xer Cleaner', 
            };
        }
        created(): void {
            this.supportedBrowser = this.supportedBrowserTest();
        }

        private supportedBrowserTest(): boolean {
            try {
                const b = new Blob();
                return typeof b.arrayBuffer === 'function';
            } catch {
                return false;
            }
        }
    }

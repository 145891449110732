
import { Component, Vue, Prop } from 'vue-property-decorator';


@Component({})
export default class Header extends Vue {
    @Prop({ type: String }) title!: string;

    
    }
